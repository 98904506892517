import React, { useRef, useState } from "react";
import st from "../styles/components/Dropdown.module.scss";
import { useLocale } from "@lib/tools/helpers";
import dynamic from "next/dynamic";
import useDetectOutside from "@hooks/useDetectOutside";
const DropdownArrow = dynamic(() => import("@assets/icons/general/DropdownArrow"));
const SecondaryDropdown = ({
  selectedOption,
  icon,
  labelText,
  name,
  placeholder,
  onSelectOption,
  options,
  id
}) => {
  const language = useLocale();
  const [active, setActive] = useState(false);
  const label = options?.find(option => option?.value === selectedOption)?.label;
  const dropDownRef = useRef(null);
  const {
    ref: optionRef
  } = useDetectOutside(true, () => {
    setActive(false);
  }, [dropDownRef]);
  return <div className={st["custom-dropdown"]} onClick={() => {
    setActive(!active);
  }} ref={dropDownRef} data-sentry-component="SecondaryDropdown" data-sentry-source-file="SecondaryDropdown.jsx">
      <div className={st["label-container"]}>
        {icon && <span className={st["dropdown-icon"]}>{icon}</span>}
        <label htmlFor={id} className={st["second-label"]}>
          {labelText}:
        </label>
        <span className={st["second-dropdown"]}>
          <label className={st["second-dropdown-label"]}>
            {label}
          </label>
          {active && <div className={st["second-dropdown-options"]} ref={optionRef}>
              {options.map(option => <label className={st["select-option"]} key={option.value} value={option.value} onClick={() => {
            onSelectOption(option.value);
          }}>
                  {option.label}
                </label>)}
            </div>}
        </span>
      </div>
      <DropdownArrow style={{
      position: 'absolute',
      right: '14px',
      transition: 'transform 200ms ease-in-out',
      transform: `rotate(${active ? 180 : 0}deg)`
    }} data-sentry-element="DropdownArrow" data-sentry-source-file="SecondaryDropdown.jsx" />
    </div>;
};
export default SecondaryDropdown;