import { NODE_API } from "@lib/api/api";
import { handleCatchPopup } from "@lib/tools/helpers";
import { useMemo, useRef } from "react";
import useSWR, { SWRConfiguration, unstable_serialize } from "swr";
import usePopup from "./usePopup";
export type RequestParams = {
  num?: number;
  category?: string;
  theme?: string;
  page_num?: number;
  search?: string;
  provider?: string | string[];
  sort_by?: "a-z" | "z-a" | "popular" | "recommended" | "recommended_mini" | "release" | "random";
  type?: string;
  grouping?: string;
  region?: string;
  country?: string;
};
export const getSlotList = async (data: RequestParams, headers: {
  region?: string;
  country?: string;
  [key: string]: any;
} = {}) => {
  const searchParams = new URLSearchParams();
  searchParams.set("region", headers.region || "");
  searchParams.set("country", headers.country || "");
  const res = await NODE_API.get<SlotsListResponse>(`v1/slots/list?${searchParams.toString()}`, {
    params: data,
    headers
  });
  const resData: SlotsListResponse = res.data || {
    count: 0,
    games: []
  };
  return resData;
};
type UseSlotsListProps = {
  data: RequestParams;
  headers: Record<string, string>;
  disabled?: boolean;
  initialData?: SlotsListResponse;
  swrOptions?: SWRConfiguration;
};
export const SLOT_LIST_KEY = (data: RequestParams) => unstable_serialize(data);
const useSlotsList = ({
  data,
  headers,
  disabled = false,
  initialData = null,
  swrOptions = {}
} = {} as UseSlotsListProps) => {
  const setMessage = usePopup();
  const cache = useRef<Map<string, SlotsListResponse>>(new Map());
  const swr = useSWR<SlotsListResponse, Error>(disabled ? null : [SLOT_LIST_KEY(data), data], async ([key, params]: [string, RequestParams]) => {
    if (cache.current.has(key)) return cache.current.get(key);
    const getParamsData = (key: string, params: RequestParams) => getSlotList(params, {
      ...headers,
      region: params?.region || headers?.region,
      country: params?.country || headers?.country
    }).then(r => {
      if (r.error) throw r.error || "er_launching_game";
      return {
        ...r,
        hash: key,
        params,
        key: [params.page_num, key].toString()
      } as SlotsListResponse;
    }).catch(e => {
      handleCatchPopup(setMessage)(e);
      return {
        count: 0,
        games: []
      } as SlotsListResponse;
    });
    const getPreviousPages = () => Promise.all(Array.from({
      length: params.page_num - 1
    }, (_, i) => i + 1).map(async p => {
      const prevHash = SLOT_LIST_KEY({
        ...params,
        page_num: p
      });
      if (cache.current.has(prevHash)) return cache.current.get(prevHash);
      return getParamsData(prevHash, {
        ...params,
        page_num: p
      });
    })).then(arr => arr.map(i => i?.games)?.flat()?.filter(Boolean)).catch(() => [] as Game[]);
    const result = {
      ...(await getParamsData(key, params)),
      previousPages: await getPreviousPages()
    };
    cache.current.set(key, result);
    return result;
  }, {
    revalidateOnMount: !initialData?.games?.length,
    revalidateOnFocus: false,
    keepPreviousData: true,
    ...(initialData && {
      fallbackData: {
        ...initialData,
        hash: SLOT_LIST_KEY(data),
        params: data,
        previousPages: []
      }
    }),
    ...swrOptions
  });
  const allPages = useMemo(() => [...(swr?.data?.previousPages || []), ...(swr?.data?.games || [])], [swr?.data?.previousPages, swr?.data?.games]);
  return {
    ...swr,
    allPages
  };
};
export default useSlotsList;
export interface SlotsListResponse {
  count: number;
  games: Game[];
  error?: any;
  // inserted in frontend
  hash?: string;
  params?: RequestParams;
  previousPages?: Game[];
}
export interface Game {
  id: number;
  name: string;
  producer: string;
  payout: string;
  url: string;
  custom_banner: any;
  type: string;
  icon: string;
  region_blocked: boolean;
  new?: boolean;
  route?: string;
}