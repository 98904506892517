import React, { useCallback, useState } from "react";
import Link from "next/link";
import PopularIcon from "@assets/svgs/PopularIcon";
import RandomIcon from "@assets/svgs/RandomIcon";
import API from "@lib/api/api";
import { useRouter } from "next/router";
import { Dict } from "@hooks/useLanguage";
import { NewLogoIcon } from "@assets/icons/general/NewLogo";
import { Button } from "@components/new-ui/button";
import { cn } from "@lib/utils";
export default function QuickLinks({
  setSortBy
}: {
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [activeLink, setActiveLink] = useState("all_slots");
  const router = useRouter();
  const handleSort = (sortType, linkName) => {
    setSortBy(sortType);
    setActiveLink(linkName);
  };
  const fetchRandomGame = useCallback(async () => {
    const {
      data
    } = await API.get("/public/random-game");
    if (data?.url) {
      router.push(data?.url);
    }
  }, [router]);
  return <div className={cn("grid grid-cols-2 md:grid-cols-4 lg:grid-cols-none gap-3 lg:flex lg:items-center lg:flex-wrap lg:w-full mt-[10px]")} data-sentry-component="QuickLinks" data-sentry-source-file="index.tsx">
      <Button variant="normal" className={cn("w-full lg:w-auto px-4 py-3 h-[42px] min-h-[42px]", activeLink === "new" ? "bg-[#0077db] border-[#0077db]" : "bg-[#262c52] border-[#282f58]", "hover:bg-[#0077db] hover:border-[#0077db]", "[&_svg]:w-[26px] [&_svg]:h-[26px] [&_svg]:absolute [&_svg]:-top-[1px] [&_svg]:-right-[1px] relative group")} onClick={() => handleSort("release", "new")} data-sentry-element="Button" data-sentry-source-file="index.tsx">
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" fill="none" viewBox="0 0 27 26" data-sentry-element="svg" data-sentry-source-file="index.tsx">
          <g clipPath="url(#clip0_9506_42918)" data-sentry-element="g" data-sentry-source-file="index.tsx">
            <path fill="#0077DB" className="group-hover:fill-white transition-colors" d="M26.643 10.991L15.81.158A.542.542 0 0015.426 0H1.343A.542.542 0 00.96.925l24.916 24.916a.542.542 0 00.925-.384V11.375a.543.543 0 00-.158-.384z" data-sentry-element="path" data-sentry-source-file="index.tsx" />
            <path fill="#FAFAFA" className="group-hover:fill-[#0077db] transition-colors" d="M12.175 8.667a.542.542 0 01-.53-.65l.732-3.662-1.985 1.987a.542.542 0 01-.766-.765l3.25-3.25a.542.542 0 01.914.488l-.732 3.663 1.986-1.985a.542.542 0 11.766.766l-3.25 3.25a.541.541 0 01-.385.158zm3.25 3.25a.543.543 0 01-.383-.158l-1.625-1.625a.542.542 0 010-.766l3.25-3.25a.542.542 0 01.765 0l1.625 1.625a.542.542 0 11-.765.766L17.05 7.266 14.566 9.75l1.241 1.242a.542.542 0 01-.382.925z" data-sentry-element="path" data-sentry-source-file="index.tsx" />
            <path fill="#FAFAFA" className="group-hover:fill-[#0077db] transition-colors" d="M16.51 9.75a.542.542 0 01-.384-.159l-1.083-1.083a.542.542 0 01.766-.766l1.083 1.083a.542.542 0 01-.382.924zm3.25 6.499a.542.542 0 01-.514-.713l.741-2.222-2.222.741a.548.548 0 01-.58-.159.541.541 0 01-.075-.597l2.167-4.333a.542.542 0 01.97.484l-1.582 3.163 2.01-.67a.547.547 0 01.698.39.54.54 0 01-.013.297l-.67 2.008 3.163-1.582a.542.542 0 01.485.97l-4.333 2.167a.54.54 0 01-.245.056z" data-sentry-element="path" data-sentry-source-file="index.tsx" />
          </g>
          <defs data-sentry-element="defs" data-sentry-source-file="index.tsx">
            <clipPath id="clip0_9506_42918" data-sentry-element="clipPath" data-sentry-source-file="index.tsx">
              <path fill="#fff" d="M0 0H26V26H0z" transform="translate(.8)" data-sentry-element="path" data-sentry-source-file="index.tsx" />
            </clipPath>
          </defs>
        </svg>
        <Dict name="new_releases" section="SlotsList" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
      </Button>
      <Button variant="normal" className={cn("w-full lg:w-auto px-4 py-3 h-[42px] min-h-[42px]", "bg-[#262c52] border-[#282f58]", "hover:bg-[#0077db] hover:border-[#0077db]", "[&_svg]:opacity-50 [&_svg]:transition-opacity [&_svg_path]:transition-colors [&_svg_path]:hover:fill-white [&_svg]:hover:opacity-100")} onClick={async () => {
      await fetchRandomGame();
    }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
        <RandomIcon data-sentry-element="RandomIcon" data-sentry-source-file="index.tsx" />
        <Dict name="random_game" section="SlotsList" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
      </Button>

      <Button variant="normal" className={cn("w-full lg:w-auto px-4 py-3 h-[42px] min-h-[42px]", activeLink === "popular" ? "bg-[#0077db] border-[#0077db]" : "bg-[#262c52] border-[#282f58]", "hover:bg-[#0077db] hover:border-[#0077db]", "[&_svg]:opacity-50 [&_svg]:transition-opacity [&_svg_path]:transition-colors [&_svg_path]:hover:fill-white [&_svg]:hover:opacity-100", activeLink === "popular" && "[&_svg]:opacity-100 [&_svg_path]:fill-white")} onClick={() => handleSort("popular", "popular")} data-sentry-element="Button" data-sentry-source-file="index.tsx">
        <PopularIcon data-sentry-element="PopularIcon" data-sentry-source-file="index.tsx" />
        <Dict name="popular_games" section="SlotsList" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
      </Button>

      <Link href={`/casino/originals`} className="w-full lg:w-auto" data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <Button variant="normal" className={cn("w-full lg:w-auto px-4 py-3 h-[42px] min-h-[42px]", "bg-[#262c52] border-[#282f58]", "hover:bg-[#0077db] hover:border-[#0077db]", "[&_svg]:opacity-50 [&_svg]:transition-opacity [&_svg_path]:transition-colors [&_svg_path]:hover:fill-white [&_svg]:hover:opacity-100")} data-sentry-element="Button" data-sentry-source-file="index.tsx">
          <NewLogoIcon width="18px" height="18px" data-sentry-element="NewLogoIcon" data-sentry-source-file="index.tsx" />
          <Dict name="originals" section="SlotsList" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
        </Button>
      </Link>
    </div>;
}