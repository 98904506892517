import React, { useRef, useState } from "react";
import st from "../styles/items/ProviderDropDown.module.scss";
import { useLocale, mergeClasses } from "@lib/tools/helpers";
import dynamic from "next/dynamic";
import DropDown from "./DropDown";
import InputItem from "@items/InputItem";
import Button from "@items/Button";
import Close from "@assets/icons/general/Close";
import SearchIcon from "@assets/icons/general/SearchIcon";
import CheckBox from "@items/CheckBox";
import useDetectOutside from "@hooks/useDetectOutside";
import ScrollBar from "./ScrollBar";
import useLanguage from "@hooks/useLanguage";
const DropdownArrow = dynamic(() => import("@assets/icons/general/DropdownArrow"));
const ProviderDropDown = ({
  selectedOptions,
  icon,
  labelText,
  name,
  placeholder,
  onSelectOption,
  onClear,
  options,
  id,
  dropDownStrategy = "absolute"
}) => {
  const language = useLocale();
  const L = useLanguage(["common", "SlotsList"]);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState(false);
  const contentToggle = useRef(null);
  const closeBtn = useRef(null);
  const {
    ref: refClose
  } = useDetectOutside(true, () => {
    setActive(false);
  }, [contentToggle, closeBtn]);
  const providers = options.filter(option => option.label.toLowerCase().includes(search.toLowerCase()));
  const providerLabel = selectedOptions.length === 0 ? L("provider") : selectedOptions.join(', ');
  return <div {...mergeClasses(st["provider-dropdown"], active && st["active"])} data-sentry-component="ProviderDropDown" data-sentry-source-file="ProviderDropdown.jsx">
      <div ref={closeBtn} className={st['close-btn']}>
        {selectedOptions.length > 0 && <Button icon={<Close />} method={onClear} classType={["square-icon", "close-btn"]} />}
      </div>
      <div className={st["provider-toggle-content"]} onClick={() => {
      setActive(!active);
    }} ref={contentToggle}>
        <div className={st["label-container"]}>
          <label htmlFor={id} className={`${st["first-label"]}`}>
            {labelText}:
          </label>
          <span className={`${st['provider-dropdown-label']}`}>
            {providerLabel}
          </span>
        </div>
        <DropdownArrow style={{
        position: 'absolute',
        right: '14px',
        transition: 'transform 200ms ease-in-out',
        transform: `rotate(${active ? 180 : 0}deg)`
      }} data-sentry-element="DropdownArrow" data-sentry-source-file="ProviderDropdown.jsx" />
      </div>
        {active && <div className={st["provider-dropdown-container"]} style={{
      opacity: `${active ? 1 : 0}`
    }} ref={refClose}>
              <InputItem classType={["row", "search", "no-margin"]} name="search" value={search} method={setSearch} placeholder={L("search")} lable={"Currency"} icon={<SearchIcon />} />
              <ScrollBar>
                <div className={st["provider-dropdown-scroll-container"]}>
                  {providers.map((option, index) => {
            return <div key={`provider-${option.value}-${index}`} {...mergeClasses(st["provider-select-btn"], selectedOptions.includes(option.value) && st["btn-active"])} onClick={() => onSelectOption(option.value)}>
                            <div style={{
                width: '20px',
                display: 'flex',
                justifyContent: 'center'
              }}>
                              <CheckBox isChecked={selectedOptions.includes(option.value)} name={option.label} id={`option-${option.label}`} readOnly={true} />
                            </div>
                            <label className={st["label"]}>
                              {option.label}
                            </label>
                            <span className={st["count"]}>
                              {option.count}
                            </span>
                          </div>;
          })}
                </div>
              </ScrollBar>
            </div>}
     
    </div>;
};
export default ProviderDropDown;