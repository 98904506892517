import SortByIcon from "@assets/svgs/SortByIcon";
import useSlotsList, { RequestParams, SlotsListResponse } from "@hooks/use-slots-list";
import { useGeoLocation } from "@hooks/useGeoLocation";
import useLanguage, { Dict } from "@hooks/useLanguage";
import useMobile from "@hooks/useMobile";
import InputItem from "@items/InputItem";
import ProviderDropDown from "@items/ProviderDropdown";
import SecondaryDropdown from "@items/SecondaryDropdown";
import { EVENTS, usePubSub } from "@lib/pubsub";
import { classnames } from "@lib/tools/helpers";
import st from "@styles/components/SlotsList.module.scss";
import { clsx } from "clsx";
import dynamic from "next/dynamic";
import { useSearchParams } from "next/navigation";
import { memo, startTransition, useEffect, useMemo, useRef, useState } from "react";
import GhostSlots from "./GhostSlots";
import LoadingSpinner from "./Loading/LoadingSpinner";
import { Button } from "./new-ui/button";
import QuickLinks from "./quick-links";
import Link from "next/link";
import { motion } from "motion/react";
import { PopularIcon, RainbetIcon, ReleasesIcons, SportsbookIcon } from "@assets/icons/homepage-icons";
const Slots = dynamic(() => import("@assets/icons/games/Slots"));
const SlotLink = dynamic(() => import("@components/slot-link_old"));
const Baccarat = dynamic(() => import("@assets/icons/games/Baccarat"));
const BlackJack = dynamic(() => import("@assets/icons/games/Blackjack"));
const RouletteSlots = dynamic(() => import("@assets/icons/games/RouletteSlots"));
const GameShowIcon = dynamic(() => import("@assets/icons/games/GameShowIcon"));
const LiveGamesIcon = dynamic(() => import("@assets/icons/games/LiveGamesIcon"));
const SlotsHourglassIcon = dynamic(() => import("@assets/icons/games/SlotsHourglassIcon"));
const CarouselSlider = dynamic(() => import("@components/carousel-slider"));
const getIconAndRoute = category => {
  switch (category) {
    case "popular_blackjack":
      return {
        icon: BlackJack,
        route: "/casino/blackjack"
      };
    case "popular_baccarat":
      return {
        icon: Baccarat,
        route: "/casino/baccarat"
      };
    case "popular_roulette":
      return {
        icon: RouletteSlots,
        route: "/casino/roulette"
      };
    case "popular_game_shows":
      return {
        icon: GameShowIcon,
        route: "/casino/live/game-shows"
      };
    case "popular_live_games":
      return {
        icon: LiveGamesIcon,
        route: "/casino/live"
      };
    case "live_casino":
      return {
        icon: LiveGamesIcon,
        route: "/casino/live"
      };
    case "newReleases":
      return {
        icon: ReleasesIcons,
        route: "/casino/slots"
      };
    case "originals":
      return {
        icon: () => <RainbetIcon className="h-[22px] w-[22px]" />,
        route: "/casino/originals"
      };
    case "sportsbook":
      return {
        icon: () => <SportsbookIcon className="h-[22px] w-[22px]" />,
        route: "/sportsbook"
      };
    case "recently_played_games":
      return {
        icon: SlotsHourglassIcon
      };
    case "popular":
      return {
        icon: PopularIcon,
        route: "/casino/slots"
      };
    default:
      return {
        icon: Slots,
        route: "/casino/slots"
      };
  }
};
interface SlotsListProps {
  heading: string;
  number?: number;
  category?: string;
  theme?: string;
  searchSection?: boolean;
  sortBy?: "a-z" | "z-a" | "popular" | "recommended" | "recommended_mini" | "release" | "random";
  setSortBy?: (sortBy: string) => void;
  carousel?: boolean | number;
  isLive?: boolean;
  grouping?: string;
  popularFormat?: boolean;
  providerName?: string;
  providerSearchArea?: boolean;
  isStatic?: boolean;
  initialData?: SlotsListResponse;
  shouldShowQuickLinks?: boolean;
}
const defaultArr = [] as any[];
const SlotsList = ({
  heading,
  number = 36,
  category = null,
  theme = null,
  searchSection = false,
  sortBy = "recommended",
  setSortBy,
  carousel = false,
  isLive,
  grouping,
  popularFormat = false,
  providerName,
  providerSearchArea,
  isStatic = false,
  initialData = null,
  shouldShowQuickLinks = false
}: SlotsListProps) => {
  const query = useSearchParams();
  const queryProvider = query.get("provider");
  const [localSearch, setLocalSearch] = useState("");
  const providerList = usePubSub(EVENTS.PROVIDERS);
  const [provider, setProvider] = useState(queryProvider === null ? defaultArr : [queryProvider]);
  const [page, setPage] = useState(1);
  const L = useLanguage(["SlotsList", "Slots", "Casino"]);
  const isMobile = useMobile({
    breakPointThree: 600
  } as any);
  const {
    countryCode,
    region
  } = useGeoLocation();
  const {
    icon: Icon,
    route
  } = getIconAndRoute(heading);
  const modalType = query.get("modal");
  const [isLoaded, setIsLoaded] = useState(false);
  const minSearchLength = useMemo(() => {
    if (localSearch.length > 0 && localSearch.length < 3) return "Please enter at least 3 characters";
    return false;
  }, [localSearch]);
  const params = useMemo(() => ({
    num: number,
    category,
    theme,
    page_num: page,
    search: localSearch && localSearch.length > 2 ? localSearch : "",
    provider: providerName ? [providerName] : provider.length === 0 ? "all" : provider,
    sort_by: sortBy,
    type: isLive ? "live" : null,
    grouping
  }) satisfies RequestParams, [category, grouping, isLive, localSearch, number, page, provider, providerName, sortBy, theme]);
  const {
    data: slotsData,
    isLoading: isLoading_,
    error,
    allPages,
    isValidating
  } = useSlotsList({
    data: params,
    headers: {
      region,
      country: countryCode
    },
    disabled: isStatic,
    initialData
  });
  const isLoading = isLoading_ || isValidating;
  const slotsList = isStatic ? initialData?.games || [] : allPages || [];
  const noResults = slotsList?.length === 0;
  const resultCount = slotsData?.count || 0;
  const options = useMemo(() => [...(providerList?.filter(provider => provider?.categories?.some(category => category.name.includes(grouping))) || []).sort((a, b) => a.name.localeCompare(b.name)).map(provider => ({
    value: provider.name,
    label: provider.name,
    count: provider.categories.find(category => category.name === grouping)?.count ?? 0
  }))], [providerList, grouping]);

  // page here means pagination not url changes
  const lastPage = useRef(params.page_num);
  const isLoadingNewPage = useMemo(() => {
    if (!isLoading) return false;
    if (params.page_num > lastPage.current) return true;
    return false;
  }, [isLoading, params.page_num]);
  useEffect(() => {
    if (localSearch !== "") {
      setPage(1);
    }
  }, [localSearch]);
  useEffect(() => {
    if (!setSortBy) return;
    startTransition(() => {
      setSortBy(sortBy);
      setPage(1);
    });
  }, [sortBy, setSortBy]);
  useEffect(() => {
    startTransition(() => {
      setProvider(queryProvider === null ? defaultArr : [queryProvider]);
      setPage(1);
    });
  }, [queryProvider]);
  useEffect(() => {
    return () => {
      setPage(1);
      setProvider(defaultArr);
      setSortBy?.(sortBy);
      setLocalSearch("");
    };
    // for especific resets, like grouping
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grouping]);
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  return <motion.section initial={{
    opacity: 0
  }} animate={{
    opacity: isLoaded ? 1 : 0
  }} transition={{
    duration: 0.3,
    ease: "easeOut"
  }} className={clsx(classnames(st, "list-container", isMobile)?.className, "overflow-x-hidden pt-1 flex flex-col gap-[14px]", searchSection && "min-h-[475px]")} data-sentry-element="unknown" data-sentry-component="SlotsList" data-sentry-source-file="slots-list.tsx">
      {heading ? <div className="flex items-center gap-5">
          <div className="flex items-center gap-[10px] [&_path]:fill-[#38A4FF]">
            <Icon width={22} height={22} />
            <p className="text-white text-lg font-medium">{L(heading)}</p>
          </div>
          {modalType !== "search" && route && <Link href={route} className=" [@media(max-width:640px)]:hidden">
              <Dict name="view_all" section="common" as="span" className="text-sm font-medium text-[#8388A7] hover:underline" />
            </Link>}
        </div> : null}
      {searchSection ? <div className={`${st["search-area"]} ${st[isMobile as any]}`}>
          <div className={`${st["row-area"]}`}>
            <InputItem classType={["search", "search-input", "full-width"]} name="search" value={localSearch} method={setLocalSearch} placeholder={L("search")} toolTip={{
          text: minSearchLength,
          forceShow: minSearchLength,
          showMode: true,
          placement: "top"
        }} icon={<svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M9.19526 9.19526C9.45561 8.93491 9.87772 8.93491 10.1381 9.19526L13.8047 12.8619C14.0651 13.1223 14.0651 13.5444 13.8047 13.8047C13.5444 14.0651 13.1223 14.0651 12.8619 13.8047L9.19526 10.1381C8.93491 9.87772 8.93491 9.45561 9.19526 9.19526Z" fill="#5B628C" stroke="#5B628C" strokeWidth="0.5" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M6.66667 3.33333C4.82572 3.33333 3.33333 4.82572 3.33333 6.66667C3.33333 8.50762 4.82572 10 6.66667 10C8.50762 10 10 8.50762 10 6.66667C10 4.82572 8.50762 3.33333 6.66667 3.33333ZM2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667Z" fill="#5B628C" stroke="#5B628C" strokeWidth="0.5" />
                </svg>} />
            <div className={`${st["filter-area"]} ${st[isMobile as any]}`}>
              {!providerSearchArea && <ProviderDropDown options={options} onSelectOption={value => {
            startTransition(() => {
              setProvider(providers => {
                if (providers.includes(value)) return providers.filter(p => p !== value);else return [...providers, value];
              });
              setPage(1);
            });
          }} onClear={() => {
            startTransition(() => {
              setProvider(defaultArr);
              setPage(1);
            });
          }} selectedOptions={provider} icon={false} labelText={L("provider_label")} name={L("provider")} placeholder={L("provider")} id="provider_secondary" />}
              <SecondaryDropdown id="sort_by_secondary" selectedOption={sortBy} icon={<SortByIcon />} name={L("sort_by")} labelText={L("sort_by")} onSelectOption={value => {
            startTransition(() => {
              setSortBy(value);
              setPage(1);
            });
          }} options={[{
            value: "recommended",
            label: L("recommended")
          }, {
            value: "popular",
            label: L("popular")
          }, {
            value: "release",
            label: L("release")
          }, {
            value: "a-z",
            label: L("alphabetical") + " (A-Z)"
          }, {
            value: "z-a",
            label: L("alphabetical") + " (Z-A)"
          }]} placeholder={L("sort_by")} />
            </div>
          </div>

          {shouldShowQuickLinks && <QuickLinks setSortBy={setSortBy} />}
        </div> : null}
      {isLoading ? <div className={`${st["slots-list"]} ${st[isMobile as any]}`}>
          <GhostSlots mini={popularFormat} />
        </div> : (noResults || error) && !isLoading ? <div className={st["no-found-message"]}>{L("no_results_found")}</div> : <div className={popularFormat ? st["lists-container__mini"] : st["lists-container"]}>
          {carousel && slotsList?.length > 3 ? <CarouselSlider items={slotsList} resultCountNumber={typeof carousel === "number" ? carousel : 8} /> : <div className={`${st["slots-list"]} ${st[isMobile as any]}`}>
              {isLoading && !isLoadingNewPage || slotsList === null || grouping !== slotsData?.params?.grouping ? <GhostSlots mini={popularFormat} /> : slotsList?.map?.(item => <SlotLink key={item.id} item={item} />)}
            </div>}
        </div>}
      {searchSection && resultCount > number && slotsList.length < resultCount && !noResults && <div className="flex justify-center mt-8">
            <Button onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (isLoading) return;
        if (isStatic) return;
        setPage(p => p + 1);
      }} variant="normal">
              {isLoading ? <LoadingSpinner type="button" /> : L("load_more")}
            </Button>
          </div>}
    </motion.section>;
};
export default memo(SlotsList);